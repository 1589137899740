<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CHANGE COMPENSATION</h4></v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-flex xs12 md12>
              <v-checkbox v-model="is_allowance_only" label="Is Allowance" dense
                          outlined></v-checkbox>
              <v-text-field
                class="mx-2"
                v-model="new_rate"
                dense
                label="New Daily Rate"
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12>
              <v-text-field
                class="mx-2"
                v-model="date_approved"
                dense
                label="Date Approved"
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
            </v-flex>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn class="w-full" color="primary" @click="done_request" v-if="!saving_request">
              Done
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
  import {mdiAlertOutline} from '@mdi/js'
  import {mapActions, mapGetters} from 'vuex'

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      is_allowance_only: false,
      alert_message: '',
      new_rate: '0',
      date_approved: '',
    }
  }
  export default {
    props: {
      member_information: Object,
      details: Object,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      if (Object.keys(this.details).length != 0) {
        this.is_allowance_only = this.details.is_allowance_only
        this.date_approved = this.details.date_of_effective
        this.new_rate = this.details.to_rate
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id']),
    },
    methods: {
      ...mapActions('employee', ['change_compensation']),
      ...mapActions('employee_compensation_history', ['edit_compensation']),
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          if (Object.keys(this.member_information).length != 0) {
            data.append('id', this.member_information.id)
            data.append('date_of_effective', this.date_approved)
            data.append('from_rate', this.member_information.compensation_rate)
            data.append('to_rate', this.new_rate)
            data.append('employee_id_created', this.employee_id)
            data.append('is_allowance_only', this.is_allowance_only ? 1 : 0)
            this.change_compensation(data)
              .then(response => {
                this.$emit('response', response)
                this.saving_data = false
              })
              .catch(error => {
                console.log(error)
              })
          } else if (Object.keys(this.details).length != 0) {
            data.append('id', this.details.id)
            data.append('date_of_effective', this.date_approved)
            data.append('to_rate', this.new_rate)
            data.append('employee_id_created', this.employee_id)
            data.append('is_allowance_only', this.is_allowance_only ? 1 : 0)
            this.edit_compensation(data)
              .then(response => {
                this.$emit('response', response)
                this.saving_data = false
              })
              .catch(error => {
                console.log(error)
              })
          }
          this.saving_request = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    },
  }
</script>
