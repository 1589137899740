<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <div
        v-if="this.member_information.id!=null && (position==='ADMIN'||position==='HUMAN RESOURCE')">
        <v-layout v-if="this.member_information.is_active===1">
          <v-flex xs12 md3>
            <v-btn
              outlined
              class="w-full"
              color="primary"
              @click="is_change_salary=true"
              v-if="!is_change_salary"
            >
              Change Branch/Position
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-flex>
        </v-layout>
      </div>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Date of Employment
            </th>
            <th class="text-uppercase">
              Date of Change
            </th>
            <th class="text-uppercase">
              Duration
            </th>
            <th class="text-uppercase">
              From Position
            </th>
            <th class="text-uppercase">
              To Position
            </th>
            <th class="text-uppercase">
              From Branch
            </th>
            <th class="text-uppercase">
              To Branch
            </th>
            <th class="text-uppercase">
              Created By
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>
              {{ item.date_started}}
            </td>
            <td>
              {{ item.date_changes }}
            </td>
            <td>
              {{ duration(item.date_started,item.date_changes) }}
            </td>
            <td>
              {{ item.from_position}}
            </td>
            <td>
              {{ item.to_position }}
            </td>
            <td>
              {{ item.from_branch_code}}
            </td>
            <td>
              {{ item.to_branch_code }}
            </td>
            <td>
              {{ item.employee_creator.last_name+', '+item.employee_creator.first_name }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="is_change_salary" persistent max-width="30%">
      <ChangeBranchOrPosition :member_information="member_information"
                              v-on:response="on_change"></ChangeBranchOrPosition>
      <v-btn color="error" @click="is_change_salary = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import moment from 'moment'
  import ChangeBranchOrPosition
    from "@/views/file_interface/search/components_emloyee/components/ChangeBranchOrPosition";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import {mdiPlusBox} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  export default {
    props: {
      member_information: Object,
      data: Array,
    },
    components: {
      snackBarDialog,
      ChangeBranchOrPosition,
    },
    setup() {
      return {
        icons: {
          mdiPlusBox,
        },
      }
    },
    data() {
      return {
        is_change_salary: false,
      }
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      duration(a, b) {
        return moment(b).diff(a, "months") + ' month/s'
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      on_change(value) {
        var color = 'error'
        if (value.status === 200) {
          color = 'success'
        }
        this.change_snackbar({
          show: true,
          color: color,
          text: value.data,
        })
        this.is_change_salary = false
      },
    }
  }
</script>
