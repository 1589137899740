<template>
  <div>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>
    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <foundation
          :key="keeyyyy"
          v-on:data="on_update_status_employee"
          :data="member_information"
          :is_personal="is_personal"
        ></foundation>
      </v-tab-item>
      <v-tab-item>
        <account
          :key="keeyyyy"
          v-on:data="on_update_status_employee"
          :cropped="cropped"
          :data="member_information"
          :is_personal="is_personal"
        ></account>
      </v-tab-item>
      <v-tab-item>
        <district-club
          :key="keeyyyy"
          v-if="position==='ADMIN'||position==='HUMAN RESOURCE'||is_personal"
          :member_information="member_information"
          :data="compensation_histories"
          :is_personal="is_personal"
        ></district-club>
      </v-tab-item>
      <v-tab-item>
        <donation
          :key="keeyyyy"
          :member_information="member_information"
          :data="memo_history"
          :is_personal="is_personal"
        ></donation>
      </v-tab-item>
      <v-tab-item>
        <participation
          :key="keeyyyy"
          :member_information="member_information"
          :data="change_history"
          :is_personal="is_personal"
        ></participation>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import Account from "@/views/file_interface/search/components_emloyee/PersonalInformation";
  import Foundation from "@/views/file_interface/search/components_emloyee/EmploymentStatus";
  import DistrictClub from "@/views/file_interface/search/components_emloyee/CompensationHistory";
  import Donation from "@/views/file_interface/search/components_emloyee/MemoHistory";
  import Participation from "@/views/file_interface/search/components_emloyee/ChangeHistory";
  import {
    mdiAccountCashOutline,
    mdiAccountOutline,
    mdiBallotOutline,
    mdiCash,
    mdiCashRefund,
    mdiCellphone,
    mdiCharity,
    mdiClipboardClockOutline,
    mdiContentSaveMoveOutline, mdiCubeScan, mdiGift,
    mdiGoogleCirclesCommunities, mdiHandshakeOutline,
    mdiInformationOutline,
    mdiShieldAccount
  } from "@mdi/js";

  export default {
    props: {
      keeyyyy: Number,
      is_personal: Boolean,
      position: String,
      cropped: String,
      member_information: Object,
      compensation_histories: Array,
      memo_history: Array,
      change_history: Array,
    },
    components: {
      Account,
      Foundation,
      DistrictClub,
      Donation,
      Participation,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiCharity,
          mdiHandshakeOutline,
          mdiCubeScan,
          mdiGift,
        },
      }
    },
    data() {
      return {
        tab: '',
        tabs: [
          {title: 'EMPLOYMENT STATUS', icon: mdiGoogleCirclesCommunities},
          {title: 'PERSONAL INFORMATIONS', icon: mdiAccountOutline},
          {title: 'COMPENSATION HISTORY', icon: mdiCharity},
          {title: 'MEMO HISTORY', icon: mdiGift},
          {title: 'CHANGE HISTORY', icon: mdiHandshakeOutline},
        ],
      }
    },
    methods: {
      async on_update_status_employee() {
        this.$emit('data', ' ')
      },
    }
  }
</script>

<style scoped>

</style>
