<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">ADD MEMO</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="date_released"
              dense
              label="Date Release"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="subject"
              dense
              label="Subject"
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
            <v-textarea
              class="mx-2"
              v-model="grounds"
              dense
              label="Grounds"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-textarea>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      is_allowance_only: false,
      alert_message: '',
      subject: '',
      grounds: '',
      date_released: '',
    }
  }
  export default {
    props: {
      member_information: Object,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id']),
    },
    methods: {

      ...mapActions('employee_memo_history', ['register_memo_employee']),
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('employee_id', this.member_information.id);
          data.append('date_of_release', this.date_released);
          data.append('subject', this.subject.toUpperCase());
          data.append('grounds', this.grounds.toUpperCase());
          data.append('employee_id_released', this.employee_id);
          this.register_memo_employee(data)
            .then(response => {
              this.$emit('response', response)
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
