<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CHANGE BRANCH OR POSITION</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="date_released"
              dense
              label="Date Effective"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              class="mx-2 mt-3"
              v-model="category_id"
              dense
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
              outlined
            ></v-select>
            <v-select
              class="mx-2 mt-3"
              v-model="position_id"
              dense
              label="Position"
              :items="position_items"
              item-value="id"
              item-text="position"
              :rules="rules.combobox_rule"
              @change="selected_position"
              outlined
            ></v-select>
            <v-select
              class="mx-2 mt-3"
              v-model="branch_id"
              dense
              label="Branch"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
              outlined
            ></v-select>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      category_id: '',
      position_id: '',
      branch_id: '',
      date_released: '',

      category_items: [],
      position_items: [],
      branch_items: [],
    }
  }
  export default {
    props: {
      member_information: Object,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    async mounted() {
      await this.initialize_change_branch_position()
        .then(response => {
          this.category_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.category_id = this.member_information.category_id
      this.position_id = this.member_information.position_id
      this.branch_id = this.member_information.branch_id
      await this.selected_category()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id']),
    },
    methods: {
      ...mapActions('employee_change_position_branch_history', ['initialize_change_branch_position', 'change_branch_position_employee']),
      ...mapActions('branch_information', ['branch_data']),
      selected_category() {
        this.position_items = []
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(this.category_id)
        if (index != -1) {
          this.position_items = this.category_items[index].employee_position
        }
      },
      selected_position() {
        var index = this.position_items.map(function (x) {
          return x.id;
        }).indexOf(this.position_id)
        if (index != -1) {
          var position_selected = this.position_items[index].position
          this.branch_items = []
          this.branch_data({
            category_id: this.category_id,
            is_region: position_selected.includes("REGION") ? 1 : 0,
            is_territory: position_selected.includes("TERRITORY") || position_selected.includes("VP") ? 1 : 0,
          })
            .then(response => {
              this.branch_items = response.data[0].active
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('employee_id', this.member_information.id);
          data.append('category_id', this.category_id);
          data.append('employee_id_created', this.employee_id);
          data.append('branch_id', this.branch_id);
          data.append('employee_position_id', this.position_id);
          data.append('date_of_effective', this.date_released);
          this.change_branch_position_employee(data)
            .then(response => {
              this.$emit('response', response)
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
