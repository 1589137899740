<template>
  <div>
    <v-card flat class="pt-4">
      <v-card-text>
        <v-row class="mt-3">
          <v-col md="12" cols="12">
            <h2>{{ status }}</h2>
            <h2>{{ status1 }}</h2>
            <h2>{{ status2 }}</h2>
            <v-simple-table dense class="pt-2">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-uppercase">
                    Date
                  </th>
                  <th class="text-uppercase">
                    Status
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="item in data_item"
                  :key="item.id"
                >
                  <td>
                    {{ item.date }}
                  </td>
                  <td>
                    {{ item.status }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col md="4" cols="12">
            <v-text-field
              v-model="data.date_of_employment"
              label="Date of Eployment"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col md="4" cols="12">
            <v-text-field
              v-if="position === 'ADMIN' || position === 'HUMAN RESOURCE' || is_personal"
              v-model="data.compensation_rate"
              label="Compensation Rate"
              dense
              outlined
              readonly
              type="number"
            ></v-text-field>
          </v-col>
          <v-col md="4" cols="12">
            <v-checkbox readonly v-model="data.is_allowance_only" label="Is Allowance" dense
                        outlined></v-checkbox>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="data.bank_type_1" label="(Primary) Bank Type" dense outlined
                          readonly></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="data.bank_account_1"
              dense
              label="(Primary) Account Name"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="data.bank_account_no_1"
              dense
              label="(Primary) Account Number"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="data.bank_type_2"
              label="(Secondary) Bank Type"
              dense
              outlined
              readonly

            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="data.bank_account_2"
              dense
              label="(Secondary) Account Name"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="data.bank_account_no_2"
              dense
              label="(Secondary) Account Number"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="data.bank_type_3"
              label="(Optional) Bank Type"
              dense
              outlined
              readonly

            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="data.bank_account_3"
              dense
              label="(Optional) Account Name"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="data.bank_account_no_3"
              dense
              label="(Optional) Account Number"
              outlined
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <div
          v-if="this.data.id != null && (position === 'ADMIN' || position === 'HUMAN RESOURCE'|| position === 'TREASURY')">
          <v-layout column wrap class="mt-3">
            <v-layout row wrap class="mx-3">
              <v-flex xs12 md3 class="mx-3" v-if="position != 'TREASURY'">
                <v-btn outlined
                       class="w-full"
                       color="error"
                       @click="change_employee_status(3)"
                       v-if="!is_change_status && this.data.is_active === 2"
                >
                  Permanent Deactivate
                </v-btn>
                <v-progress-circular
                  :size="50"
                  :width="5"
                  color="error"
                  indeterminate
                  v-if="is_change_status"
                ></v-progress-circular>
                <v-btn outlined
                       class="w-full"
                       color="warning"
                       @click="change_employee_status(2)"
                       v-if="!is_change_status && this.data.is_active === 1"
                >
                  Deactivate
                </v-btn>
                <v-progress-circular
                  :size="50"
                  :width="5"
                  color="warning"
                  indeterminate
                  v-if="is_change_status"
                ></v-progress-circular>
              </v-flex>
              <v-flex xs12 md3 class="mx-3"
                      v-if="this.data.is_active === 2">
                <v-btn outlined class="w-full" color="success" @click="change_employee_status(1)"
                       v-if="!is_change_status">
                  Activate
                </v-btn>
                <v-progress-circular
                  :size="50"
                  :width="5"
                  color="success"
                  indeterminate
                  v-if="is_change_status"
                ></v-progress-circular>
              </v-flex>
              <v-flex xs12 md3 class="mx-3"
                      v-if="this.data.is_active === 1 && position != 'TREASURY'">
                <v-btn outlined class="w-full" color="primary" @click="change_compensate"
                       v-if="!is_change_salary">
                  Change Compensation
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-if="is_change_salary"></v-progress-circular>
              </v-flex>
              <v-flex xs12 md3 class="mx-3" v-if="position != 'TREASURY'">
                <v-btn outlined class="w-full" color="primary" @click="change_status_of_employee"
                       v-if="!is_change_status_employee">
                  Change Status
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-if="is_change_status_employee"></v-progress-circular>
              </v-flex>
              <v-flex xs12 md3 class="mx-3 mt-2">
                <v-btn outlined class="w-full" color="primary" @click="change_bank_details"
                       v-if="!is_change_bank_details && position != 'HUMAN RESOURCE'">
                  Change Bank Details
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-if="is_change_bank_details"></v-progress-circular>
              </v-flex>
              <v-flex xs12 md3 class="mx-3 mt-2" v-if="employee_id===1">
                <v-btn outlined class="w-full" color="primary" @click="change_effective_date"
                       v-if="!is_change_effective_date && position === 'ADMIN'&& employee_id === 1">
                  Change Effective Date
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-if="is_change_effective_date"></v-progress-circular>
              </v-flex>
            </v-layout>
          </v-layout>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="is_change_salary" persistent max-width="30%">
      <ChangeCompensation :key="key" :member_information="data" :details="{}"
                          v-on:response="on_change"></ChangeCompensation>
      <v-btn color="error" @click="is_change_salary = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_change_status_employee" persistent max-width="30%">
      <ChangeStatus :key="key" :member_information="data" v-on:response="on_change"></ChangeStatus>
      <v-btn color="error" @click="is_change_status_employee = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_change_bank_details" persistent max-width="30%">
      <EditBankDetails :key="key" :member_information="data"
                       v-on:response="on_change"></EditBankDetails>
      <v-btn color="error" @click="is_change_bank_details = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_deactivate" persistent max-width="30%">
      <DeactivateEmployee :key="key" :member_information="data"
                          v-on:response="on_change"></DeactivateEmployee>
      <v-btn color="error" @click="is_deactivate = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_change_effective_date" persistent max-width="30%" :key="key">
      <ChangeEffectiveDate :key="key" :member_information="data"
                           v-on:response="on_change"></ChangeEffectiveDate>
      <v-btn color="error" @click="is_change_effective_date = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
import ChangeCompensation
  from '@/views/file_interface/search/components_emloyee/components/ChangeCompensation'
import ChangeStatus
  from '@/views/file_interface/search/components_emloyee/components/ChangeStatus'
import ChangeEffectiveDate
  from '@/views/file_interface/search/components_emloyee/components/ChangeEffectiveDate'
import EditBankDetails
  from '@/views/file_interface/search/components_emloyee/components/EditBankDetails'
import DeactivateEmployee
  from '@/views/file_interface/search/components_emloyee/components/DeactivateEmployee'
import {mapActions, mapGetters} from 'vuex'
import {mdiPlusBox} from '@mdi/js'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import moment from 'moment'

export default {
  props: {
    data: Object,
    is_personal: Boolean,
  },
  components: {
    snackBarDialog,
    ChangeCompensation,
    ChangeStatus,
    EditBankDetails,
    DeactivateEmployee,
    ChangeEffectiveDate,
  },
  setup() {
    return {
      icons: {
        mdiPlusBox,
      },
    }
  },
  data() {
    return {
      is_change_salary: false,
      is_change_status: false,
      is_change_status_employee: false,
      is_change_bank_details: false,
      is_change_effective_date: false,
      is_deactivate: false,
      status: '',
      status1: '',
      status2: '',
      key: 0,
      data_item: [],
    }
  },
  mounted() {
    this.duration_status()
  },
  watch: {
    data: function () {
      this.duration_status()
    }
  },
  computed: {
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    ...mapGetters('authentication', ['position', 'employee_id']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('employee', ['change_status_employee']),
    duration_status() {
      this.status = ''
      this.status1 = ''
      this.status2 = ''
      if (this.data.id != null) {
        if (this.data.is_active === 0) {
          this.status = 'DEACTIVATED/RESIGN'
        } else {
          this.status = 'ACTIVE'
        }
        this.status = this.status + ' ' + this.duration(this.data.date_of_employment, moment().format('yyyy-MM-DD'))
        if (this.data.status === 'Trainee') {
          this.status = this.status + ' (Trainee) ' + this.duration(this.data.date_of_employment, moment().format('yyyy-MM-DD'))
        }
        if (this.data.status === 'Probationary') {
          this.status1 = this.status1 + '( ' + this.data.status + ' )' + ' ' + this.duration(this.data.date_of_probitionary, moment().format('yyyy-MM-DD'))
        }
        if (this.data.status === 'Regular') {
          this.status2 = this.status2 + '( ' + this.data.status + ' )' + ' ' + this.duration(this.data.date_of_regular, moment().format('yyyy-MM-DD'))
        }
        var dep = []
        if (this.data.is_allowance_only) {
          dep.push({id: 1, date: this.data.date_of_employment, status: 'Comission'})
          if (this.data.date_of_probitionary != null) {
            dep.push({id: 2, date: this.data.date_of_probitionary, status: 'Comission+Allowance'})
          }
          if (this.data.date_of_regular != null) {
            dep.push({
              id: 3,
              date: this.data.date_of_regular,
              status: 'Comission+Allowance+Incentives'
            })
          }
        } else {
          dep.push({id: 1, date: this.data.date_of_employment, status: 'Trainee'})
          if (this.data.date_of_probitionary != null) {
            dep.push({id: 2, date: this.data.date_of_probitionary, status: 'Probationary'})
          }
          if (this.data.date_of_regular != null) {
            dep.push({id: 3, date: this.data.date_of_regular, status: 'Regular'})
          }
        }

        this.data_item = dep
      }
    },
    duration(a, b) {
      var months_counter = moment(b).diff(a, 'months')
      var year = parseInt(months_counter / 12)
      var month = parseInt(months_counter - year * 12)

      var duration = year > 0 ? year + ' year/s' + (month > 0 ? ' & ' + month + ' month/s' : '') : month + ' month/s'
      return duration
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    change_employee_status(value) {
      if (value === 2) {
        this.is_deactivate = true
      } else {
        const data = new FormData()
        data.append('id', this.data.id)
        data.append('status', value)
        data.append('reason', value === 2 ? 'RESIGN' : value === 3 ? 'REMOVE' : null)
        data.append('employee_id_created', this.employee_id)
        this.change_status_employee(data)
          .then(response => {
            this.$emit('data', response)
            this.saving_data = false
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    on_change(value) {
      var color = 'error'
      if (value.status === 200) {
        color = 'success'
        this.is_change_salary = false
        this.is_change_status_employee = false
        this.is_change_bank_details = false
        this.is_deactivate = false
        this.$emit('data', ' ')
      }
      this.change_snackbar({
        show: true,
        color: color,
        text: value.data,
      })
    },
    change_compensate() {
      this.is_change_salary = true
      this.key++
    },
    change_status_of_employee() {
      this.key++
      this.is_change_status_employee = true
    },
    change_bank_details() {
      this.key++
      this.is_change_bank_details = true
    },
    change_effective_date() {
      this.key++
      this.is_change_effective_date = true
    },
  },
}
</script>
