<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text class="d-flex">
        <v-avatar
          rounded
          size="120"
          class="me-6"
        >
          <v-img :src="cropped"></v-img>
        </v-avatar>
        <!-- upload photo -->
        <div>
          <v-btn
            color="primary"
            outlined
            class="mt-10"
            @click="updating"
          >
            Update Details
          </v-btn>
        </div>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="data.category.category"
              label="Category"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="data.branch.branch_code"
              label="Branch"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="data.employee_position.position"
              label="Position"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-card
            flat
          >
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col
                md="4"
                cols="12"
              ></v-col>
              <v-row>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.last_name"
                    label="Last Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.first_name"
                    label="First Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.middle_name"
                    label="Middle Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="data.date_of_birth"
                    label="Date of Birth"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="data.gender"
                    label="Gender"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="data.civil_status"
                    dense
                    outlined
                    label="Civil Status"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="3"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.religion"
                    label="Religion"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="branch_region"
                    dense
                    outlined
                    label="Address By Region"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="branch_province"
                    dense
                    outlined
                    label="Address By Province"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="branch_city"
                    dense
                    outlined
                    label="Address By City"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.address"
                    label="Specify Address"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="3"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.email_address"
                    label="Email Address"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="3"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.contact_no"
                    label="Contact #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-toolbar dense dark class="mt-6">
                <v-toolbar-title><h6 class="font-weight-light">BENEFITS</h6>
                </v-toolbar-title>
              </v-toolbar>
              <v-layout row wrap class="align-center mx-2 mt-5">
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="data.sss_no"
                    label="SSS #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="data.ph_no"
                    label="Philhealth #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="data.pag_ibig_no"
                    label="Pag-ibig #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="data.tin_no"
                    label="TIN #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-toolbar dense dark class="mt-6">
                <v-toolbar-title><h6 class="font-weight-light">IN CASE OF EMERGENCY
                </h6>
                </v-toolbar-title>
              </v-toolbar>
              <v-layout row wrap class="align-center mx-2 mt-5">
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="data.emergency_name"
                    label="Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="data.emergency_address"
                    label="Address"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="data.emergency_contact_no"
                    label="Contact #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="can_update" persistent max-width="80%">
      <NewEmployee
        v-on:data="on_update_info"
        :key="this.key"
        :for_update="true"
        :information_details="this.data"></NewEmployee>
      <v-btn color="error" @click="can_update = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import NewEmployee from '@/views/file_interface/register/NewEmployee'
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";

  const initialState = () => {
    return {
      can_update: false,
      key: 0,
      branch_city: '',
      branch_province: '',
      branch_region: '',
    }
  }
  export default {
    components: {
      NewEmployee,
      snackBarDialog,
    },
    props: {
      data: Object,
      cropped: String,
    },
    data() {
      return initialState()
    },
    mounted() {
      this.branch_city = this.data.branch_city != null ? this.data.branch_city.city : ''
      this.branch_province = this.data.branch_province != null ? this.data.branch_province.province : ''
      this.branch_region = this.data.branch_region != null ? this.data.branch_region.region : ''
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee', ['initialize_new_employee', 'is_employee_already_encoded', 'register_new_employee']),
      updating() {
        this.key++
        this.can_update = true
      },
      on_update_info() {
        this.can_update = false
        this.$emit('data', 'Update')
      }
    },
  }
</script>
