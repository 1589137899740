<template>
  <div>
    <v-card flat class="pt-4">
      <v-card-text>
        <v-layout row wrap class="mx-2">
          <v-flex xs12 md3
                  v-if="this.member_information.id != null && ((position === 'ADMIN'&&employee_id===1&&employee_id===2||employee_id===3) || (position === 'HUMAN RESOURCE' && employee_id===417))">
            <v-btn outlined class="w-full" color="primary" @click="change_compensation_details()"
                   v-if="!is_change_salary">
              Change Compensation
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-flex>
          <v-flex xs12 md12 class="mt-4">
            <v-data-table dense
                          :headers="headers"
                          :items="data"
            >
              <template v-slot:item="{ item }">
                <tr
                >
                  <td>
                    {{ item.date_of_effective }}
                  </td>
                  <td>
                    {{ formatPrice(item.from_rate) }}
                  </td>
                  <td>
                    {{ formatPrice(item.to_rate) }}
                  </td>
                  <td>
                    {{ item.employee_creator.last_name + ', ' + item.employee_creator.first_name }}
                  </td>
                  <!-- <td v-if="employee_id === 3 || employee_id === 1">
                    <v-icon
                      v-if="item.employee_approver === null"
                      color="success"
                      @click="change_stat_compensation(1, item.id)"
                      >{{ icons.mdiThumbUp }}
                    </v-icon>
                    {{ ' ' }}
                    <v-icon
                      v-if="item.employee_approver === null"
                      color="error"
                      @click="change_stat_compensation(0, item.id)"
                      >{{ icons.mdiThumbDown }}
                    </v-icon>
                    {{
                      item.employee_approver != null? item.employee_approver.last_name + ', ' + item.employee_approver.first_name: ''
                    }}
                  </td> -->
                  <td>
                    {{
                    item.employee_approver != null
                    ? item.employee_approver.last_name + ', ' + item.employee_approver.first_name
                    : ''
                    }}
                  </td>
                  <td>
                    {{ item.is_approved === 1 ? 'Approved' :
                    item.employee_id_approver2 === null ? 'Pending to CFO' :
                    (item.employee_id_approver === null?'Pending to President':'Disapproved') }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-dialog v-model="is_change_salary" persistent max-width="30%">
      <ChangeCompensation
        :key="key"
        :member_information="member_information"
        :details="{}"
        v-on:response="on_change"
      ></ChangeCompensation>
      <v-btn color="error" @click="is_change_salary = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiPlusBox, mdiThumbUp, mdiThumbDown} from '@mdi/js'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ChangeCompensation
    from '@/views/file_interface/search/components_emloyee/components/ChangeCompensation'
  import {mapActions, mapGetters} from 'vuex'

  export default {
    props: {
      member_information: Object,
      data: Array,
    },
    components: {
      ChangeCompensation,
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiPlusBox,
          mdiThumbUp,
          mdiThumbDown,
        },
      }
    },
    data() {
      return {
        is_change_salary: false,
        key: 0,
        headers: [
          {text: 'Date Effective', value: 'name', sortable: false},
          {text: 'From Rate', value: 'name', sortable: false},
          {text: 'To Rate', value: 'name', sortable: false},
          {text: 'Created By', value: 'name', sortable: false},
          {text: 'Approved By', value: 'name', sortable: false},
          {text: 'Status', value: 'name', sortable: false},
        ],
      }
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['employee_id', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee_compensation_history', ['approve_compensation']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      on_change(value) {
        var color = 'error'
        if (value.status === 200) {
          color = 'success'
          this.is_change_salary = false
        }
        this.change_snackbar({
          show: true,
          color: color,
          text: value.data,
        })

      },
      change_stat_compensation(value, id) {
        const data = new FormData()
        data.append('id', id)
        data.append('is_approved', value)
        data.append('employee_id_approver', this.employee_id)
        this.approve_compensation(data)
          .then(response => {
            var color = 'error'
            if (value.status === 200) {
              color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      change_compensation_details() {
        this.key++
        this.is_change_salary = true
      },
    },
  }
</script>
